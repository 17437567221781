<script setup lang="ts">
// import { UserLoginDialog, UserLogoutDialog } from '#components'
import SIGNIN from '~/assets/image/icon/SIGNIN.png'

const emits = defineEmits(['showTask'])

// const userStore = useUser()
const router = useRouter()

// const loginDialogRef = ref<InstanceType<typeof UserLoginDialog> | null>(null)
// const logoutDialogRef = ref<InstanceType<typeof UserLogoutDialog> | null>(null)

// function handleLoginDialog() {
//   if (loginDialogRef.value) {
//     loginDialogRef.value.loginVisible = true
//   }
// }

// // 打开退出弹窗
// function handleLogoutDialog() {
//   if (logoutDialogRef.value) {
//     logoutDialogRef.value.visible = true
//   }
// }
function goService() {
  router.push('/service')
}
</script>

<template>
  <header fixed top-0 z-99 w-full bg-111425 class="min-h-54px">
    <div max-w-screen="2xl" mx-auto w-full flex flex-col items-center>
      <div pos-relative h-54px w-full flex items-center justify-between lg:pb-0>
        <!-- <p w-16 lg:hidden /> -->
        <!--  logo -->
        <BaseIcon
          name="logo"

          width="3"
          height="3"

           absolute translate-x-15px cursor-pointer transition-opacity lg:hidden hover:op-90
          @click="$router.push('/')"
        />
        <BaseIcon
          name="logo"
          width="3"
          height="3"
          hidden
          cursor-pointer
          transition-opacity
          lg:inline
          hover:op-90
          @click="$router.push('/')"
        />
        <div mx-auto text="white 14px center">
          <NuxtLink to="/sis" class="search-warpper flex items-center justify-center">
              <span class="flex-1">搜索您感兴趣的内容
              </span>
              <img h-5 w-5 src="~/assets/image/icon/icon_search.png" />
          </NuxtLink>
          <!-- 首页 -->
        </div>
        <div absolute right-0 flex flex-row>
          <!-- <img mr-2 h-6 :src="SIGNIN" @click="emits('showTask')" /> -->
          <van-icon size="20" color="#ffffff" mr-4 name="service-o" @click="goService" />
        </div>
        <!-- <div hidden lg:inline>
          <Search w-200 />
        </div> -->
        <!-- 登录 -->

        <!-- 登录完成 -->
        <!-- <div v-if="userStore.isRealUser" flex="~ items-center">
          <NuxtLink to="/user">
            <NuxtImg
              hover="~ op75"
              :src="`${userStore.userInfo.topImagesUrl}`"
              w-11
              cursor-pointer
              transition
              duration-150
              lg:inline
              lg:w-13
            />
          </NuxtLink>
          <div lg:flex="~ col justify-between" ml-2.5 hidden>
            <p mb-3>
              欢迎 {{ userStore.userInfo.userName }}
            </p>
            <BaseButton type="info" text="退出" round w-20 p-0.5 @click="handleLogoutDialog" />
          </div>
        </div>
        <div v-if="!userStore.isRealUser">
          <p w-16 cursor-pointer text-center text-white lg:hidden @click="handleLoginDialog">
            请登录
          </p>
          <BaseButton text="登录" round hidden w-28 lg:inline @click="handleLoginDialog" />
        </div> -->
      </div>
      <!-- 搜索 -->
      <!-- <Search lg:hidden /> -->
      <!-- 登陆弹窗 -->
      <!-- <UserLoginDialog ref="loginDialogRef" /> -->
      <!-- 退出弹窗 -->
      <!-- <UserLogoutDialog ref="logoutDialogRef" /> -->
    </div>
  </header>
</template>

<style>
.search-warpper{
  width: 260px;
  height: 24px;
  background: #090C1C;
  border-radius: 0.9375rem;
  color: #414660;
}
</style>
