<script setup lang="ts">
// import icon_h_paihangbang from '~/assets/image/home/main/icon_h_paihangbang.png'
// import icon_h_pianshang from '~/assets/image/home/main/icon_h_pianshang.png'
// import icon_h_group from '~/assets/image/home/main/icon_h_group.png'
// import icon_h_vip from '~/assets/image/home/main/icon_h_vip.png'
// import icon_h_xianmian from '~/assets/image/home/main/icon_h_xianmian.png'
import { Subject } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { MainApi } from '~/net/apis/main_api'
import { TaskApi } from '~/net/apis/task_api'

const { internalStepFunc } = useInternal_step_func()
const HomeStore = useHome()
const router = useRouter()
// const taskStatus = ref(false)
const DisIndex = ref(0)
// const AdcolumnItem = computed(() => ((HomeStore.Notice[DisIndex.value] as any) || {}))
// const AdcolumnStatus = ref(HomeStore.popupShow)
const { y } = useWindowScroll()
const quickAccess = useObservable(from(TaskApi.queryAdList({ tabId: `${1}` })).pipe(map(({ data }) => data)))

const sub = new Subject<HomeTab | null>()
const active = ref(0)
const tagsList = useObservable(
  from(MainApi.tabQuery()).pipe(
    map(({ data }) => data),
    tap((data) => sub.next(data[active.value]))
  )
)
const tagsItem = computed(() => (tagsList.value ? tagsList.value[active.value] : {}))
const titleList = useObservable(
  sub.pipe(
    switchMap((res) =>
      MainApi.videoCom({ pageNum: 1, pageSize: 10, tabId: String(res?.tabId) || String(tagsItem.value.tabId) || '0' })
    ),
    map(({ data }) => data.record || [])
  )
)

onMounted(() => {
  active.value = Number(window.sessionStorage.getItem('tagsId')) || 0
})

onUnmounted(() => sub.unsubscribe())
onUnmounted(() => {
  window.sessionStorage.setItem('tagsId', `${active.value}`)
})
function showTask() {
  // taskStatus.value = true
  // AdcolumnStatus.value = true
}
console.log('tagsItem', tagsItem, tagsList)

// // 使用 watch 监听 tagsItem 的变化
// watch(tagsItem, (newValue, oldValue) => {
//   if (newValue && !deepEqual(newValue, oldValue)) {
//     sub.next(newValue)
//   }
// })

function clickTabHandle() {
  sub.next(tagsItem.value)
}
function headerClick(group: VideoGroup) {
  if (!group)
    return
  const groupId = group.groupId
  router.push(`/home-combine/${groupId}`)
  HomeStore.setGroupName(group.groupTitle || '')
}
</script>

<template>
  <div pb-3>
    <TheHeader
:class="[y > 5 && 'shadow-md shadow-slate-800']"
      @show-task="showTask"
      />
    <van-tabs
      v-model:active="active"
      title-inactive-color="#e8e8e8"
      mb-10
      mt-12
      min-h-screen
      bg-111425
      @click-tab="clickTabHandle"
    >
      <van-tab v-for="tagItem in tagsList" :key="tagItem.tabId" px-3 :title="`${tagItem.tabName}`">
        <div v-if="active === 0">
          <div h-150px>
            <BannerPage :key="0" :banner-type="{ type: 1, code: 0 }" />
          </div>
          <div grid grid-cols-6 w-full gap-2 grid-gap-10px overflow-hidden px-0 py-20px>
            <div v-for="(item, k) in quickAccess" :key="k" flex @click="internalStepFunc(item, item.targetUrl || '')">
              <div min-w-55px flex-1 flex-col text-center>
                <ImgWrapper class="mb-2 h-50px rounded-2" :src="`${item.content}`" />
                <span whitespace-nowrap text-12px ct-e8e8e8>{{ item.caption }}</span>
              </div>
            </div>
          </div>

          <div class="banner2">
            <BannerPage :key="1" :banner-type="{ type: 1, code: 1 }" height="h-80px" class="pb-24px" />
          </div>
        </div>
        <CommonCellView2 v-for="items in titleList" :key="items.groupId" :title-data="items" @header-click="() => headerClick(items)" />
      </van-tab>
    </van-tabs>
    <!-- <Task v-model="taskStatus" /> -->
    <!-- <Adcolumn v-model="HomeStore.popupShow" /> -->
     <!-- v-if="HomeStore.Notice" -->
    <TheFooter fixed bottom-0 h-50 w-full />
  </div>
</template>

<style>
.banner2{
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
