export enum InternalStepType {
  // 0:活动
  activity = 0,
  // 1:外部地址
  outLink = 1,
  // 2:固定界面
  innerPage = 2,
  // 3:影片详情界面
  film = 3,
  // 4:女优详情界面
  figure = 4,
  // 5:厂商详情界面
  company = 5,
  // 6
  none = 6
}

export const InternalStepTypeVal = Object.values(InternalStepType).slice(
  (Object.values(InternalStepType).length / 2) * -1
)
