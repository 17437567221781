/// 内部跳转目标
export enum InternalStepInnerType {
  // HOMEPAGE(0, "首页"),
  home,
  // HOMEPAGE_FREE(1, "首页-限免区"),
  homeFree,
  // HOMEPAGE_VIP(2, "首页-VIP区"),
  homeVIP,
  // HOMEPAGE_PRODUCER(3, "首页-片商列表"),
  homeProducer,
  // HOMEPAGE_RANK(4, "首页-排行榜"),
  homeRank,
  // ACTRESS(5, "集美列表"),
  actress,
  // ACTIVITY(6, "活动列表"),
  activity,
  // MINE(7, "我的"),
  mine,
  // MINE_PERSON(8, "我的-个人"),
  minePerson,
  // MINE_PERSON_MOBILE(9, "我的-个人-绑定手机"),
  minePersonMobile,
  // MINE_PERSON_ID_CARD(10, "我的-身份卡"),
  minePersonIdCard,
  // MINE_PERSON_CHANGE_ACCOUNT(11, "我的-个人-更换账号"),
  minePersonChangeAccount,
  // MINE_PERSON_SWITCH_ACCOUNT(12, "我的-个人-切换账号"),
  minePersonSwitchAccount,

  // MINE_MEMBER(13, "我的-开通会员"),
  mineMember,
  // MINE_SHARE(14, "我的-推广分享"),
  mineShare,
  // MINE_VIEW_RECORD(15, "我的-观看记录"),
  mineViewRecord,
  // MINE_PURCHASE_RECORD(16, "我的-购买记录"),
  minePurChaseRecord,
  // MINE_FLOW_RECORD(17, "我的-充值记录"),
  mineFlowRecord,

  // MINE_OFFLINE_CATCH(18, "我的-离线缓存"),
  mineOfflineCache,
  // MINE_COLLECT(19, "我的-收藏"),
  mineCollect,
  // MINE_CONNECT(20, "我的-交流群");
  mineConnect
}

export const InternalStepInnerTypeVal = getEnumVal(InternalStepInnerType)
